<template>
    <div>
      <v-radio-group
      v-model= "DateRadioOption"
      row mandatory>
      <v-radio
        data-testid="AllTime"
        label="All Time"
        value="AllTime"
        v-on:change="setSelectedDateOption('AllTime')"
      ></v-radio>
      <v-radio
        data-testid="DateRange"
        label="Enrollment Time Range"
        value="DateRange"
        v-on:change="setSelectedDateOption('DateRange')"
      ></v-radio>
      </v-radio-group>
      <date-range v-if="this.selectedDateRadioOption === 'DateRange'" ></date-range>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DateRange from './DatePicker'
export default {
  name: 'RadioField',
  components: {
    DateRange
  },
  computed: {
    ...mapState([
      'selectedDateRadioOption'
    ]),
    DateRadioOption: {
      get () {
        return this.selectedDateRadioOption
      },
      set (){}
    }
  },
  methods: {
    ...mapActions(['setSelectedDateOption'])
  }
}
</script>
