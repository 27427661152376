<template>
  <div>
    <v-row>
    <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              data-testid="EnrollmentStartDate"
              v-model="EnrollmentStartDate"
              label="Enrollment Start Date:"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="startDateMin"
            :max="maxDate"
            v-model="EnrollmentStartDate"
            v-on:change="setSelectedEnrollmentStartDate(EnrollmentStartDate)"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              data-testid="EnrollmentEndDate"
              v-model="EnrollmentEndDate"
              label="Enrollment End Date:"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="EnrollmentStartDate"
            :max="maxDate"
            v-model="EnrollmentEndDate"
            @input="menu2 = false"
            v-on:change="setSelectedEndollmentEndDate(EnrollmentEndDate)"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Vuex, mapState, mapActions } from 'vuex'

export default {
  name: 'DateRange',
  data: () => ({
    menu1: false,
    menu2: false,
    EnrollmentStartDate: '',
    EnrollmentEndDate: ''
  }),
  computed: {
    startDateMin () {
      return new Date('2014-01-01').toISOString().split('T')[0]
    },
    maxDate () {
      return new Date().toISOString().split('T')[0]
    }
  },
  methods: {
    ...mapActions(['setSelectedEnrollmentStartDate', 'setSelectedEndollmentEndDate'])
  }
}
</script>
<style>
.form-group{
  margin-left: 14px !important;
  margin-right: 25px !important;
  width: 250px;
}
.dateSelection{
    border: 1px solid #417CF2;
    border-radius: 5px;
    margin-right: 50px;
    padding: 5px 5px;
    width: 100%;
    height: 40px;
}
</style>
