<template>
  <v-card width="700" class="pa-3 mx-auto mt-5" >
      <h1 class="display-1" style="flex: auto">{{ Heading }}</h1>
    <v-container fluid>
    <v-row >
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          v-model="DeploymentCode"
          :items="deploymentCodesServerBased"
          v-on:change="setSelectedDeploymentCode(DeploymentCode)"
          label="Deployment Code"
        ></v-select>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          v-model="CurrentPatientStage"
          :items="stage"
          v-on:change="setCurrentPatientStageMapping(CurrentPatientStage)"
          label="Stage"
        ></v-select>
      </v-col>
      <br><br>
      <v-row>
      <v-col
      class="d-flex"
        cols="12"
        sm="6">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="ReportFromDate"
              :label= "ReportFromDateLabel"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="startDateMin"
            :max="maxDate"
            v-model="ReportFromDate"
            v-on:change="setSelectedReportFromDate(ReportFromDate)"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
      class="d-flex"
      cols="12"
      sm="6">
        <v-menu
        class="d-flex"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="ReportTillDate"
              :label="ReportTillDateLabel"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="ReportFromDate"
            :max="maxDate"
            v-model="ReportTillDate"
            @input="menu2 = false"
            v-on:change="setSelectedReportTillDate(ReportTillDate)"
          ></v-date-picker>
        </v-menu>
       </v-col>
      </v-row>
    <v-row>
      <v-col>
      <EnrollmentRadioField/>
      </v-col>
    </v-row>
    <v-col
        class="d-flex"
        cols="12"
        sm="6"
        v-if="Heading === 'Action Report'"
      >
        <v-select
          v-model="ReportType"
          :items="reportType"
          v-on:change="setSelectedReportType(ReportType)"
          label="Report Type"
        ></v-select>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
        v-if=" Heading === 'Dosing Report' "
      >
        <v-checkbox
           v-model="IncludeNotTakenDays"
           label="Include Not taken days"
           v-on:change="setSelectedIncludeNotTakenDays(IncludeNotTakenDays)"
        />
      </v-col>
      <v-col>
          <v-btn
        class="mr-4"
         @click="clearReportFilters()">Clear Filters</v-btn>
      </v-col>
      <v-col>
          <v-btn
        class="mr-4"
         @click="submit">submit</v-btn>
      </v-col>
        <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-if="isLoading"
    ></v-progress-linear>
    </v-row>
    </v-container>
  </v-card>
</template>
<script>
import EnrollmentRadioField from './RadioField'
import { mapState, mapActions } from 'vuex'
import router from '../../router/index'
import { initializePlatformMatomo } from '../../../utils/matomoTracking'
import { routeNames, getCurrentPatientStageMapping, patientStageTypes, actionReportTypes } from '../../../utils/dataValidations'
import Vue from 'vue'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

export default {
  props: ['Heading', 'ReportFromDateLabel', 'ReportTillDateLabel'],
  data: () => ({
    IncludeNotTakenDays: true,
    reportType: actionReportTypes,
    menu1: false,
    menu2: false,
    stage: patientStageTypes,
    date: new Date().toISOString().substr(0, 10),
    ReportFromDate: '',
    ReportTillDate: '',
    DeploymentCode: '',
    CurrentPatientStage: [],
    ReportType: '',
    ApiPath: '',
    datePartName: ''
  }),
  components: { EnrollmentRadioField },
  computed: {
    ...mapState([
      'isAuth',
      'isLoading',
      'isProd',
      'platform',
      'selectedReportType',
      'selectedDateRadioOption',
      'selectedReportFromDate',
      'selectedReportTillDate',
      'selectedincludeNotTakenDays',
      'selectedEnrollmentStartDate',
      'selectedEnrollmentEndDate',
      'selectedDeploymentCode',
      'selectedCurrentPatientStage',
      'filterData',
      'emailId',
      'deploymentCodesServerBased'
    ]),
    startDateMin () {
      return new Date('2014-01-01').toISOString().split('T')[0]
    },
    maxDate () {
      return new Date().toISOString().split('T')[0]
    }
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    this.setDeploymentName(this.$route.query.platform)
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
    this.setDeploymentName(this.$route.query.platform)
    this.getDeploymentCodes()
    initializePlatformMatomo()
  },
  beforeUpdate: function () {
    if (this.platform !== this.$route.query.platform && !!this.platform) {
      this.setDeploymentName(this.$route.query.platform)
      this.getDeploymentCodes()
      this.clearApiFilters()
      this.ReportFromDate = ''
      this.ReportTillDate = ''
      this.DeploymentCode = ''
      this.CurrentPatientStage = []
      this.IncludeNotTakenDays = true
      this.ReportType = ''
    }
  },
  methods: {
    ...mapActions([
      'loading',
      'setDeploymentName',
      'setSelectedReportFromDate',
      'setSelectedReportTillDate',
      'setSelectedDeploymentCode',
      'setSelectedCurrentPatientStage',
      'setSelectedIncludeNotTakenDays',
      'setSelectedReportType',
      'setFilters',
      'clearApiFilters',
      'setServerBasedDeploymentCodes'
    ]),
    getDeploymentCodes (){
      this.setServerBasedDeploymentCodes({ data: null, path: '/v1/deployments' })
    },
    clearReportFilters: function () {
      this.clearApiFilters()
      this.ReportType = ''
      this.ReportFromDate = ''
      this.ReportTillDate = ''
      this.DeploymentCode = ''
      this.CurrentPatientStage = []
      this.IncludeNotTakenDays = true
    },
    setCurrentPatientStageMapping: function (selectedPatientStageType) {
      let mappedPatientStage = []
      mappedPatientStage = getCurrentPatientStageMapping(selectedPatientStageType)
      this.setSelectedCurrentPatientStage(mappedPatientStage)
    },
    checkForErrors: function (){
      var errorCount = 0
      this.datePartName = 'Dose'
      this.ApiPath = `/Reports/DosingReport`
      if (this.Heading === 'Action Report'){
        this.datePartName = 'Action'
        this.ApiPath = `/v1/admin/action-report`
        if (this.selectedReportType === '') {
          Vue.toasted.error("Please select Report Type", { duration: 2000 })
          errorCount++
        }
      }
      if (this.selectedDeploymentCode === '') {
        Vue.toasted.error("Please select Deployment Code", { duration: 2000 })
        errorCount++
      }

      if (this.selectedReportFromDate === '' || this.selectedReportTillDate === ''){
        Vue.toasted.error("Please select " + this.datePartName + " Dates", { duration: 2000 })
        errorCount++
      }
      if (this.selectedReportFromDate !== '' && this.selectedReportTillDate !== ''){
        var StartDate = new Date(this.selectedReportFromDate).toISOString().split('T')[0]
        var EndDate = new Date(this.selectedReportTillDate).toISOString().split('T')[0]
        if (StartDate > EndDate){
          Vue.toasted.error(this.datePartName + " Start date should be before " + this.datePartName + " Till date", { duration: 2000 })
          errorCount++
        }
        if ((EndDate - EndDate) < 181){
          Vue.toasted.error("Difference between " + this.datePartName + " Start date and " + this.datePartName + " Till date should be less than 180 days", { duration: 2000 })
          errorCount++
        }
      }

      if (this.selectedDateRadioOption === 'DateRange') {
        if (this.selectedEnrollmentStartDate === '' || this.selectedEnrollmentEndDate === '') {
          Vue.toasted.error("Please select Enrollment Dates", { duration: 2000 })
          errorCount++
        }
        if (this.selectedEnrollmentStartDate !== '' && this.selectedEnrollmentEndDate !== '') {
          var startDate = new Date(this.selectedEnrollmentStartDate).toISOString().split('T')[0]
          var endDate = new Date(this.selectedEnrollmentEndDate).toISOString().split('T')[0]
          if (startDate > endDate) {
            Vue.toasted.error("'Enrollment Start' date should be before 'Enrollment End' date", { duration: 2000 })
            errorCount++
          }
        }
      }
      if (this.selectedCurrentPatientStage.length === 0) {
        Vue.toasted.error("Please select Patient Stage", { duration: 2000 })
        errorCount++
      }
      return errorCount
    },
    submit () {
      if (this.checkForErrors() === 0) {
        var data = {
          DeploymentCode: this.selectedDeploymentCode,
          CurrentPatientStage: this.selectedCurrentPatientStage,
          DateOption: this.selectedDateRadioOption,
          EnrollmentStartDate: this.selectedEnrollmentStartDate,
          EnrollmentEndDate: this.selectedEnrollmentEndDate,
          ReportFor: this.selectedReportType,
          IncludeNotTakenDays: this.selectedincludeNotTakenDays,
          UserEmail: this.emailId
        }
        data[this.datePartName + 'FromDate'] = this.selectedReportFromDate
        data[this.datePartName + 'TillDate'] = this.selectedReportTillDate
        this.setFilters(data)
        this.$emit('submit', this.ApiPath)
        this.clearReportFilters()
      }
    }
  }
}
</script>
