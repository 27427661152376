<template>
  <ReportForm Heading= 'Action Report' ReportFromDateLabel= 'Action From Date' ReportTillDateLabel= 'Action Till Date' @submit="sendFormData($event)"/>
</template>

<script>
import ReportForm from './sharedcomponents/ReportsForm'
import { mapState, mapActions } from 'vuex'
import { adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, getUsernameAndPassword } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
import Vue from 'vue'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

export default {
  components: { ReportForm },
  computed: {
    ...mapState([
      'isProd',
      'platform',
      'filterData'
    ])
  },
  mounted: function () {
  },
  beforeUpdate: function () {
  },
  methods: {
    ...mapActions([
      'loading'
    ]),
    toLowerCamelCase (input) {
      if (input === "") {
        return input
      }
      const parts = input.split(/(?=[A-Z])/) // Split by uppercase letters
      var result = parts[0].toLowerCase()
      for (let i = 1; i < parts.length; i++) {
        result += parts[i][0].toUpperCase() + parts[i].substring(1).toLowerCase()
      }
      return result
    },
    async sendFormData (apiPath){
      if (this.filterData === null) {
        return
      }
      const transformedData = {}
      for (const key in this.filterData) {
        const lowerCamelKey = this.toLowerCamelCase(key)
        transformedData[lowerCamelKey] = this.filterData[key]
      }
      const path = apiPath
      try {
        this.loading(true)
        const usernameAndPassword = getUsernameAndPassword(this.isProd)
        const data = await ApiClient.post(usernameAndPassword.Username, usernameAndPassword.Password, transformedData, path, 'registryENV')
        if (data.data.success === true) {
          const anchor = document.createElement('a')
          anchor.target = '_blank'
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data.data.data.reportContent)
          anchor.download = data.data.data.reportName
          anchor.click()
          adminLogData(this.$route.query.platform + ' Action Report', data.data.data.reportName, apiResponseType.SUCCESS)
        }
        if (data.data.success === false) {
          adminLogData(this.$route.query.platform + ' Action Report', data.data.dataReportName, apiResponseType.FAILED)
          Vue.toasted.error(data.data.Error.Message, { duration: 2000 })
        }
        this.loading(false)
      } catch (ex) {
        this.loading(false)
        Vue.toasted.error(apiResponseType.FAILED, { duration: 2000 })
      }
    }
  }
}
</script>
